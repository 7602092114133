import * as React from 'react'

/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from 'gatsby';
import { Button, Divider, Flex, Grid, Box, NavLink } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

const Layout = ({ slugs, children }) => {
  return (
    <div
      sx={{
        width: ['100%', '80%', '60%'],
        ml: 'auto',
        mr: 'auto',
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          color: 'primary',
          height: '55px',
          width: '100%',
          px: 2,
          py: 2,
          mb: 30,
        }}
      >
        <NavLink href='https://www.dfscrunch.com'>
          <StaticImage
            alt='dfs crunch logo'
            src='../images/logo.svg'
          />
        </NavLink>
        <Box>
          <Link to='https://www.dfscrunch.com/tool'>
            <Button
              sx={{
                bg: 'primaryBtn',
                height: 36,
                width: 140,
                fontSize: 14,
                padding: '5px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              CRUNCH TOOL
            </Button>
          </Link>
        </Box>
      </div>
      <Grid
        gap={2}
        columns={[2, '1fr 2fr']}
      >
        <aside
          sx={{
            flexGrow: 1,
            flexBasis: 'sidebar',
            borderRight: [null, '2px solid lightGray', '2px solid lightGray'],
            mr: 3,
            maxHeight: '70vh',
            overflow: 'scroll',
          }}>
          <Flex as='nav' sx={{ flexDirection: 'column' }}>
            {
              slugs.allMdx.nodes.map(node => (
                <NavLink
                  sx={{
                    fontWeight: 500,
                  }}
                  key={node.id}
                  href={`/${node.slug}`}
                  p={2}
                >
                  {node.frontmatter.title}
                </NavLink>
              ))
            }
          </Flex>
        </aside>
        <div sx={{ minHeight: '72vh' }}>
          {children}
        </div>
      </Grid>

      <Divider />

      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          color: 'primaryBtn',
          height: '55px',
          width: '100%',
          px: 2,
          py: 2,
          mb: 30,
        }}
      >
        <NavLink
          href='https://www.dfscrunch.com'
          sx={{ fontSize: '12px' }}
        >
          © 2021 DFS Crunch
        </NavLink>
        <Box>
          <Link
            to='https://www.dfscrunch.com/privacy'
            sx={{
              fontSize: '12px',
              textDecoration: 'none',
              mr: 3,
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to='https://www.dfscrunch.com/terms'
            sx={{
              fontSize: '12px',
              textDecoration: 'none',
            }}
          >
            Terms of Service
          </Link>
        </Box>
      </div>
    </div>
  )
}

export default Layout
